import { ColorSchema } from 'vendor/material-minimal/palette';
import { FC, HTMLProps, ReactNode } from 'react';

import { getDOMProps, isRenderable } from 'app/shared/utils/react';
import styled from 'styled-components';

import {
  CircularProgress,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material';
import { U21Spacer } from 'app/shared/u21-ui/components/layout/U21Spacer';
import { U21Typography } from 'app/shared/u21-ui/components/display/typography/U21Typography';

interface U21ProgressProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  color?: ColorSchema;
  size?: number;
  value: number;
  variant?: 'linear' | 'circular';
  linearProgressProps?: LinearProgressProps;
}

export const U21Progress: FC<U21ProgressProps> = ({
  color = 'primary',
  children,
  size = 40,
  value,
  variant = 'linear',
  ...rest
}) => {
  if (variant === 'linear') {
    return (
      <LinearContainer horizontal {...getDOMProps(rest)}>
        <StyledLinearProgress
          color={color}
          value={value}
          variant="determinate"
          {...(rest.linearProgressProps ?? {})}
        />
        {isRenderable(children) && (
          <>
            {typeof children === 'number' || typeof children === 'string' ? (
              <U21Typography color="text.secondary" variant="caption">
                {children}
              </U21Typography>
            ) : (
              children
            )}
          </>
        )}
      </LinearContainer>
    );
  }
  return (
    <CircularContainer {...getDOMProps(rest)}>
      <CircularProgressBackground
        size={size}
        value={100}
        variant="determinate"
      />
      <CircularProgress
        size={size}
        color={color}
        value={value}
        variant="determinate"
      />
      {isRenderable(children) && (
        <CircularContentContainer>
          {typeof children === 'number' || typeof children === 'string' ? (
            <U21Typography color="text.secondary" variant="caption">
              {children}
            </U21Typography>
          ) : (
            children
          )}
        </CircularContentContainer>
      )}
    </CircularContainer>
  );
};

const LinearContainer = styled(U21Spacer)`
  display: flex;
  align-items: center;
`;

const StyledLinearProgress = styled(LinearProgress)`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.palette.grey[200]};
  height: 9px;
`;

const CircularContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

const CircularProgressBackground = styled(CircularProgress)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${(props) => props.theme.palette.grey[200]};
`;

const CircularContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
